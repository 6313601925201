export const STATES = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];

export const DEFAULT_ADDRESS = [
    {
      id: 1,
      name: "Joe Russo",
      number: "2132434645",
      line1: "Vasant Colony",
      line2: "Shivaji Road",
      landmark: "Near XYZ Garden",
      pincode: "403021",
      city: "Nagpur",
      state: "Maharashtra",
      isPrimary: true,
    },
  ];

  export const DUMMY_ADDRESSES=[
    {
      id: 2,
      name: "William Jackson",
      number: "4321098765",
      line1: "Riverdale",
      line2: "Main Street",
      landmark: "Next to PQR Stadium",
      pincode: "500081",
      city: "Hyderabad",
      state: "Telangana",
      isPrimary: false
    },
    {
      id: 3,
      name: "Emma Watson",
      number: "9876543210",
      line1: "Rose Villa",
      line2: "Park Street",
      landmark: "Opposite ABC Mall",
      pincode: "500036",
      city: "Hyderabad",
      state: "Telangana",
      isPrimary: false
    },
    {
      id: 4,
      name: "John Doe",
      number: "8765432109",
      line1: "Sunrise Apartments",
      line2: "MG Road",
      landmark: "Near PQR Park",
      pincode: "600042",
      city: "Chennai",
      state: "Tamil Nadu",
      isPrimary: false
    },
    {
      id: 5,
      name: "Sophia Smith",
      number: "7654321098",
      line1: "Orchard Lane",
      line2: "Gandhi Nagar",
      landmark: "Beside MNO School",
      pincode: "700026",
      city: "Kolkata",
      state: "West Bengal",
      isPrimary: false
    },
    {
      id: 6,
      name: "Michael Johnson",
      number: "6543210987",
      line1: "Palm View",
      line2: "Main Road",
      landmark: "Adjacent to UVW Hospital",
      pincode: "110001",
      city: "New Delhi",
      state: "Delhi",
      isPrimary: false
    },
    {
      id: 7,
      name: "Olivia Anderson",
      number: "5432109876",
      line1: "Hillside Apartments",
      line2: "MG Road",
      landmark: "Near XYZ Garden",
      pincode: "600018",
      city: "Chennai",
      state: "Tamil Nadu",
      isPrimary: false
    },
    {
      id: 8,
      name: "Samantha Wilson",
      number: "3210987654",
      line1: "Greenwood Villa",
      line2: "MG Road",
      landmark: "Opposite XYZ Mall",
      pincode: "600043",
      city: "Chennai",
      state: "Tamil Nadu",
      isPrimary: false
    },
    {
      id: 9,
      name: "Daniel Roberts",
      number: "2109876543",
      line1: "Pineview Apartments",
      line2: "Park Lane",
      landmark: "Near ABC Park",
      pincode: "400012",
      city: "Mumbai",
      state: "Maharashtra",
      isPrimary: false
    },
    {
      id: 10,
      name: "Sophie Anderson",
      number: "1098765432",
      line1: "Sunset Villa",
      line2: "MG Road",
      landmark: "Opposite PQR Mall",
      pincode: "600055",
      city: "Chennai",
      state: "Tamil Nadu",
      isPrimary: false
    }
  ];
  