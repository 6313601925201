import "./Loader.css"

const Loader = () => {
    return (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col items-center justify-center w-screen h-screen overflow-hidden bg-gray-950 opacity-90">
            <div className="preloader">
            <svg className="cart" role="img" aria-label="Shopping cart line animation" viewBox="0 0 128 128" width="128px" height="128px" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="8">
                    <g className="cart__track" stroke="hsla(0,10%,10%,0.1)">
                        <polyline points="16,40 16,88 48,104 80,88 80,40 48,24 16,40 48,32 48,72 80,88 80,40 48,24 48,32 48,40 16,88" />
                        <circle cx="102" cy="111" r="15" />
                    </g>
                    <g className="cart__lines stroke-purple-600" stroke="currentColor">
                        <polyline className="cart__top" points="16,40 16,88 48,104 80,88 80,40 48,24 16,40 48,32 48,72 80,88 80,40 48,24 48,32 48,40 16,88" strokeDasharray="338 338" strokeDashoffset="-338" />
                        <g className="cart__wheel2" transform="rotate(90,102,111)">
                            <circle className="cart__wheel-stroke" cx="102" cy="111" r="15" strokeDasharray="81.68 81.68" strokeDashoffset="81.68" />
                        </g>
                    </g>
                </g>
            </svg>
            <div className="text-center preloader__text">
                <p className="text-gray-100 preloader__msg">Estamos crawleando libros para ti.</p>
                <p className="text-gray-100 preloader__msg preloader__msg--last">Conjuramos a las Musas de la Literatura.</p>
            </div>
                </div>
        </div>
    );
};

export default Loader;
